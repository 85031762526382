import datastoriesSVG from 'assets/icons/hub-data-stories.svg'
import domainSVG from 'assets/icons/hub-domains.svg'
import mapSVG from 'assets/icons/hub-map.svg'
import aboutSVG from 'assets/icons/hub-about.svg'


export const topLinks = [
  {
    title: 'Data Stories',
    text: 'Data Stories',
    Icon: datastoriesSVG,
    to: '/datastories',
    collapsedText: 'Stories'
  },
  {
    title: 'Domains Data',
    text: 'Domains Data',
    Icon: domainSVG,
    to: '/domains',
    collapsedText: 'Domains'
  },
  {
    title: 'Maps',
    text: 'Maps',
    Icon: mapSVG,
    to: '/map',
    visible: (_, place) => place && place.hasMap
  },
  {
    title: 'About',
    text: 'About',
    Icon: aboutSVG,
    to: '/about'
  }
]

export const bottomLinks = []
